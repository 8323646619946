import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "xcontainer" }
const _hoisted_2 = { class: "pb-4 pr-0 pr-md-3 pt-3 row" }
const _hoisted_3 = { class: "col-6 col-md-2 col-sm-6 offset-md-8" }
const _hoisted_4 = {
  class: "col-6 col-md-2 col-sm-6",
  style: {"margin-top":"3px"}
}
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_starter = _resolveComponent("starter")!
  const _component_GeneralData = _resolveComponent("GeneralData")!
  const _component_payables = _resolveComponent("payables")!
  const _component_depositable = _resolveComponent("depositable")!
  const _component_methods = _resolveComponent("methods")!

  return (!$setup.loading)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($setup.onSubmit && $setup.onSubmit(...args)), ["prevent"])),
        autocomplete: "off"
      }, [
        _createVNode(_component_starter, { mode: $setup.mode }, null, 8, ["mode"]),
        _createVNode(_component_GeneralData, {
          mode: $setup.mode,
          catalogs: $setup.catalogs
        }, null, 8, ["mode", "catalogs"]),
        _createVNode(_component_payables, { mode: $setup.mode }, null, 8, ["mode"]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_depositable, { mode: $setup.mode }, null, 8, ["mode"]),
            _createVNode(_component_methods, { mode: $setup.mode }, null, 8, ["mode"])
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-block btn-outline-secondary mr-5 pl-3 pr-3",
              style: {"margin":"10px 10px 10px 0"},
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.cancel && $setup.cancel(...args)))
            }, "Cancelar ")
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-block btn-blue-deg mb-2 mr-0 mr-md-5 mt-2",
              style: {"height":"38px"},
              disabled: $setup.sending || $setup.paymentForm.total === 0
            }, "Guardar ", 8, _hoisted_5)
          ])
        ])
      ], 32))
    : _createCommentVNode("", true)
}